.logo {
  font-family: 'Pacifico', cursive;
}


/* Container for the homepage */
.homepage-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

/* Each word post */
.word-post {
  scroll-snap-align: start;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  min-height: 100vh;
  /* Make each post the height of the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
}

/* Styling for each word's detail page */
.word-detail {
  text-align: center;
  max-width: 600px;
}

.word-detail h2 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.word-detail p.def {
  font-size: 1.6rem;
  line-height: 1.5;
}

.word-detail p.ety {
  font-size: 1.1rem;
  line-height: 1.5;
}